.navbar {
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    position: sticky;
    top: 0px;
    z-index: 10;
    left: 0px;
    background-color: black;
    padding: 0px 24px;
  }

  .smallerScreens{
    display: none;
  }

  
  h1{
    margin: 0px !important;
  }

  .navbar-logo {
    color: #fff;
    justify-self: start;
    cursor: pointer;
    margin-bottom: 0px !important;
  }
  
  .fa-react {
    margin-left: 0.5rem;
    font-size: 1.6rem;
  }
  
  .nav-menu {
    display: grid;
    grid-template-columns: repeat(5, auto);
    gap: 20px;
    margin-bottom: 0px !important;
    list-style: none;
    text-align: center;
    width: 45vw;
    justify-content: end;
  }
  
  .nav-links {
    color: #fff;
    text-decoration: none;
    padding: 0px 5px;
    font-weight: 500;

  }
  
  .nav-links:hover {
    transition: all 0.2s ease-in;
    text-decoration: none !important;
    color: white;
    font-weight: 500;
  }
  
  .fa-bars {
    color: #DC143C;
  }
  
  .nav-links-mobile {
    display: none;
  }
  
  .menu-icon {
    display: none;
  }
  

  @media screen and (max-width: 960px) {
   .navbar{
    position: fixed;
    top: 0px;
    width: 100%;
    left: 0px;
   }
   
    .nav-menu {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 100vh;
      position: absolute;
      top: 80px;
      left: -100%;
      opacity: 1;
      transition: all 0.5s ease;
    }

    
  .smallerScreens{
    display: block !important;
  }

  .biggerScreens{
    display: none !important;
  }
  
    .nav-menu.active {
      background: black;
      left: 0;
      opacity: 1;
      padding: 0px !important;
      justify-content: flex-start !important;
      transition: all 0.5s ease;
      z-index: 4;
    }
  
    .nav-links {
      text-align: center;
      padding: 2rem;
      width: 100%;
      display: table;
    }
  
    .nav-links:hover {
      border-radius: 0;
    }
  
    .navbar-logo {
      position: absolute;
      top: 20px;
      left: 20px;
      
    }
  
    .menu-icon {
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      transform: translate(-100%, 60%);
      font-size: 1.8rem;
      cursor: pointer;
    }
  
    .fa-times {
      color: #DC143C;
      font-size: 2rem;
    }
  
    .nav-links-mobile {
      display: block;
      text-align: center;
      padding: 1.5rem;
      margin: 2rem auto;
      border-radius: 4px;
      width: 80%;
      background: #4ad9e4;
      text-decoration: none;
      color: #fff;
      font-size: 1.5rem;
    }
  
    .nav-links-mobile:hover {
      background: #fff;
      transition: 250ms;
    }
  
  }
  