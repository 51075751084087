@for $i from 0 through 100 {
  .m_#{$i} {
    margin: #{$i}px !important;
  }
  .mt_#{$i} {
    margin-top: #{$i}px !important;
  }
  .mb_#{$i} {
    margin-bottom: #{$i}px !important;
  }
  .mr_#{$i} {
    margin-right: #{$i}px !important;
  }
  .ml_#{$i} {
    margin-left: #{$i}px !important;
  }
}

.m_auto{
  margin: auto !important;
}
