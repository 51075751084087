#AdminDashboard{
    .topRow{
        background: #fcfdfd;
        border: 0.5px solid #d6dae9;
        box-shadow: 0 4px 8px hsla(0,0%,44.7%,.08);
        border-radius: 8px;
        padding: 24px;
        display: flex;
    }

    .singleBox{
        width: 25%;
        border-right: 1px solid #d6dae9;
        padding-left: 16px;
    }

    .singleBox:last-child{
        border-right: 0px !important;
    }

    .graphBackground{
        background: #fcfdfd;
        border: 0.5px solid #d6dae9;
        box-shadow: 0 4px 8px hsla(0,0%,44.7%,.08);
        border-radius: 8px;
        padding: 24px;
    }

    .dateField{
        background: #fcfdfd;
        border: 1px solid #d6dae9;
        border-radius: 4px;
        padding: 10px 16px;
    }
}