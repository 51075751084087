#Cart{
    #page-header {
        height: 40vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        background-size: cover;
        padding: 14px;
    }
    
    #page-header h2,
    #page-header p {
        color: #fff;
    }
    

    
#cart {
    overflow-x: auto;
}

#cart table {
    width: 100%;
    border-collapse: collapse;
    table-layout: fixed;
    white-space: nowrap;
}

#cart table img {
    width: 70px;
}

#cart table td:nth-child(1) {
    width: 100px;
    text-align: center;
}

#cart table td:nth-child(2) {
    width: 150px;
    text-align: center;
}

#cart table td:nth-child(3) {
    width: 250px;
    text-align: center;
}

#cart table td:nth-child(4),
#cart table td:nth-child(5),
#cart table td:nth-child(6) {
    width: 150px;
    text-align: center;
}

#cart table td:nth-child(5) input {
    width: 70px;
    padding: 10px 5px 10px 15px;
}

#cart table thead {
    border: 1px solid #e2e9e1;
    border-left: none;
    border-right: none;
}

#cart table thead td {
    font-weight: 700;
    text-transform: uppercase;
    font-size: 13px;
    padding: 18px 0;
}

#cart table tbody tr td {
    padding-top: 15px;
}

#cart table tbody td {
    font-size: 13px;
}

#cart-add {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

#cuopon {
    width: 50%;
    margin-bottom: 30px;
}

#cuopon h3,
#subtotal h3 {
    padding-bottom: 15px;
}

#cuopon input {
    padding: 10px 20px;
    outline: none;
    width: 60%;
    margin-right: 10px;
    border: 1px solid #e2e9e1;
}

#cuopon button,
#subtotal button {
    background: #aaaaaa;
    color: #fff;
    padding: 12px 20px;
}

#subtotal {
    width: 50%;
    margin-bottom: 30px;
    border: 1px solid #e2e9e1;
    padding: 30px;
}

#subtotal table {
    border-collapse: collapse;
    width: 100%;
    margin-bottom: 20px;
}

#subtotal table td {
    width: 50%;
    border: 1px solid #e2e9e1;
    padding: 10px;
    font-size: 13px;
}

}