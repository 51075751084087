
#MerchantDashboard{
    background-color: white;
    overflow-y      : scroll;
    width           : 100%;
    height          : calc(100vh);
    padding         : 0px 3%;
    .singleCard{
        background   : white;
        box-shadow   : rgba(0, 0, 0, 0.24) 0px 3px 8px;
        border-radius: 8px;
        padding      : 24px;
        flex         : 1 1 0px;
        margin-right : 16px;
    }

    thead tr{
        border-top-right-radius: 4px;
        border-top-left-radius : 4px;
    }

    tbody{
        background: #FFFFFF;
    }

    .table-responsive {
        border-radius: 6px;
        overflow     : hidden;
    }

    tbody tr{
        th, td{
            padding: 32px 14px;
        }
    }

    tbody tr:hover{
        background-color: #f5f7fc;
        cursor          : pointer;
    }

    .outerCircle{
        border       : 2px solid #E9ECEF;
        border-radius: 50%;
        position     : absolute;
        left         : 0px;
        top          : 0px;
    }

    .MuiCircularProgress-circle {
        stroke-linecap: round;
    }

    .table{
        overflow: hidden;
        .headings{
            background   : #fff;
            border-radius: 8px 8px 0px 0px;
            padding      : 16px 25px;
        }

        .body{
            background: #FFFFFF;
            padding   : 20px 25px;
            min-height: 80px;
        }

        .border-radius-bottom{
            border-radius: 0px 0px 8px 8px;
        }

        .rotateDown{
            transform  : rotate(139deg);
            margin-top : 8px;
            margin-left: 8px;
        }
        

    }

    .progressBarSection{
        height: 80%;

    }

    .indicator{
        height       : 10px;
        width        : 10px;
        border-radius: 50%;
    }

    .singleCard:last-child{
        margin-right: 0px;
    }

    .bar{
        top      : 60%;
        transform: translate(-50%, -50%);
        position : absolute;
        left     : 100px;
    }

    .table{

    }
    

    @media screen and (max-width: 1000px) {
        .singleCard{
            max-width: 200px !important;
        }
    }
}


#MerchantProducts{
    padding: 32px;
    #MerchantShop{
        #page-header {
            height         : 40vh;
            display        : flex;
            flex-direction : column;
            justify-content: center;
            text-align     : center;
            background-size: cover;
            padding        : 14px;
        }

        #page-header h2,
        #page-header p {
            color: #fff;
        }

        #pagination {
            text-align: center;
        }

        #pagination a {
            text-decoration: none;
            background     : #aaaaaa;
            padding        : 15px 20px;
            border-radius  : 4px;
            color          : #fff;
            font-weight    : 600;
        }

        #pagination a i {
            font-size  : 16px;
            font-weight: 600;
        }


          /* Single Product */

        #prodetails {
            display   : flex;
            margin-top: 20px;
        }

        #prodetails .single-pro-image {
            width       : 40%;
            margin-right: 50px;
        }

        .small-img-group {
            display        : flex;
            justify-content: space-between;
        }

        .small-img-col {
            flex-basis: 24%;
            cursor    : pointer;
        }

        #prodetails .single-pro-details {
            width      : 50%;
            padding-top: 30px;
        }

        #prodetails .single-pro-details h4 {
            padding: 40px 0 20px 0;
        }

        #prodetails .single-pro-details h2 {
            font-size: 26px;
        }

        #prodetails .single-pro-details select {
            display      : block;
            padding      : 5px 10px;
            margin-bottom: 10px;
        }

        #prodetails .single-pro-details input {
            width       : 50px;
            height      : 47px;
            padding-left: 10px;
            font-size   : 16px;
            margin-right: 10px;
        }

        #prodetails .single-pro-details input:focus {
            outline: none;
        }

        #prodetails .single-pro-details button {
            background: #aaaaaa;
            color     : #fff;
        }

        #prodetails .single-pro-details span {
            line-height: 25px;
        }


        #product1 {
            text-align: center;
        }

        #product1 .pro-container {
            display        : flex;
            flex-wrap      : wrap;
            justify-content: space-between;
            padding-top    : 20px;
        }

        #product1 .pro {
            width        : 23%;
            min-width    : 250px;
            padding      : 10px 12px;
            border       : 1px solid #cce7d0;
            border-radius: 25px;
            transition   : .2s;
            position     : relative;
            cursor       : pointer;
            box-shadow   : 20px 20px 30px rgba(0, 0, 0, 0.02);
            margin       : 15px 0;
        }

        #product1 .pro:hover {
            box-shadow: 20px 20px 30px rgba(0, 0, 0, 0.06);
        }

        #product1 .pro img {
            width        : 100%;
            border-radius: 20px;
        }

        #product1 .pro .des {
            text-align: start;
            padding   : 10px 0;
        }

        #product1 .pro .des span {
            color    : #606063;
            font-size: 12px;
        }

        #product1 .pro .des h5 {
            padding-top: 7px;
            color      : #1a1a1a;
            font-size  : 14px;
            transition : 0.2s;
        }

        #product1 .pro:hover .des h5 {
            color: #aaaaaa;
        }

        #product1 .pro .des i {
            color    : rgb(240, 177, 18);
            font-size: 12px;
        }

        #product1 .pro .des h4 {
            padding-top: 7px;
            font-size  : 15px;
            font-weight: 700;
            color      : #aaaaaa;
        }

        #product1 .pro .cart, #product1 .pro .trash {
            width           : 40px;
            height          : 40px;
            line-height     : 40px;
            border-radius   : 50px;
            background-color: #e8f6ea;
            font-weight     : 500;
            color           : #aaaaaa;
            border          : 1px solid #cce7d0;
            transition      : 0.2s;
            position        : absolute;
            bottom          : 20px;
            right           : 10px;
        }

        #product1 .pro .cart:hover {
            color      : #fff;
            background : #aaaaaa;
            font-weight: 400;
        }

        #product1 .pro .trash:hover {
            color      : #fff;
            background : red;
            font-weight: 400;
        }

    }



}

#AddNewProduct{

    .pro .trash:hover {
        color      : #fff;
        background : red;
        font-weight: 400;
    }

    .height-fixed{
        max-height    : 470px;
        overflow-y: scroll;
    }

    .imageUploader{
        border       : 3px dashed #ccc;
        width        : 200px;
        background-color: #F2F6FF !important;
        height       : 200px;
        margin       : 8px auto;
        border-radius: 6px;
        margin-right : 16px;
    }


 .fa-trash, .fa-edit {
        width           : 35px;
        height          : 35px;
        line-height     : 40px;
        border-radius   : 50px;
        background-color: #e8f6ea;
        font-weight     : 500;
        color           : #aaaaaa;
        border          : 1px solid #cce7d0;
        transition      : 0.2s;
    }

    .cart:hover {
        color      : #fff;
        background : #aaaaaa;
        font-weight: 400;
    }

    .trash:hover {
        color      : #fff;
        background : red;
        font-weight: 400;
    }

    .singleImage{
        border       : 1px solid #d3d3d3;
        border-radius: 4px;
    }

    .removeIcon{
        width           : 22px;
        height          : 22px;
        background-color: #f5f7fc;
        top             : -8px;
        right           : -8px;
        box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
    }

    .singleType{
        margin-right : 16px;
        padding      : 8px 16px;
        border-radius: 6px;
        background: #f2f2f2;
        color: #41536c;
    
    }

    .selectedType{
        background-color: #F2F6FF;
        outline         : 1px solid color(primary50);
        color           : color(primary50)
        
    }

}




.NavselectedItem :hover{
    svg path {
        fill: color(primary50)
    }
}
#sidebar {  
    height          : 100vh;
    z-index         : 1100 !important;
    top             : 0px;
    position        : sticky;
    width           : 5vw;
    max-width       : 72px;
    box-shadow      : 0px 4px 4px rgba(128, 128, 128, 0.32);
    background-color: color(neutral0);
    border-right    : 0.5px solid color(neutral20);
    ;


    .borderBottomItems{
        box-shadow: 0px 1px 0px color(neutral20);
    }
        
        
        
        


        .pSide{
            height          : 100%;
            background-color: color(neutral0);
            width           : 5vw;
            max-width       : 72px;
            box-shadow      : 1px 0px 0px color(neutral20);
            display         : flex;
            flex-direction  : column;
            justify-content : space-between;
        }

        .subBrandImageBox{
            position: absolute;
            top     : 16px;
            right   : 10px;
        }
        
        .subBrandImage{
            border: 1px solid #F2F4F7;
        }

        .logoutMenu{
            z-index   : 1000;
            width     : 270px;
            position  : absolute;
            bottom    : 10px;
            left      : 45px;
            background: #FCFCFD;
        }

        .singleItemLogoutMenu{
            padding: 8px 0px 8px 24px;
        }


        .userProfileIcon{
            padding   : 12px 8px;
            box-shadow: 0px -1px 0px color(neutral20);
            position  : absolute;
            bottom    : 0px;
        }

        .borders{
            border-bottom: 0.5px solid color(neutral10) !important;
        }

        .logo {
            display        : flex;
            flex-direction : column;
            align-items    : center;
            justify-content: center;
            height         : 60px;
        }

        .logo img:hover, .activeLogo{
            box-shadow   : 0px 0px 0px 4px rgba(55, 112, 251, 0.15);
            transition   : all ease-in-out .2s;
            margin-bottom: 2px;
        }

        .bar {
            position     : absolute;
            border-bottom: 0.5px solid #F0F0F0 !important;
            right        : 0%;
            top          : 59px;
            width        : 100%;
        }

        .navPartial {
            width     : 5vw;
            max-width : 72px;
            margin-top: 86px;
            text-align: center;
        }
        
        

        .icons {
            cursor: pointer;
        }

        .NavselectedItem path, .NavselectedItem rect, .NavselectedItem circle{
            transition: ease 0.3s;
            fill      : color(primary50);
            stroke    : color(primary50);
        }

        .active:hover{
            path{
                transition: ease 0.3s;
                fill      : color(primary50);
            }

            circle, rect{
                transition: ease 0.3s;
                fill      : color(primary50);
                stroke    : color(primary50);
            }

        }


        .active{
            background-color: none !important;
            border-radius   : 8px;
            transition      : ease 0.3s;
            width           : 32px;
            height          : 32px;
        }
        .profile {
            position: absolute;
            bottom  : 1.8%;
            left    : 21.5%;
        }



    .sideBarMain {
        position        : relative;
        display         : none;
        background-color: color(neutral0);
        width           : 270px;
        transition      : all .3s ease;
        box-shadow      : 1px 0px 0px #DFE1E6;

        .sidebarHeading{
            height     : 56px;
            padding    : 16px 0px 16px 32px;
            display    : flex;
            align-items: center;
            font-weight: 400;
            font-size  : 18px;
            line-height: 24px;
            color      : color(neutral100);
        }

        .paragraphText {
            font-size  : 14px;
            font-weight: normal;
            color      : color(neutral40)
        }

        .active .paragraphText {
            font-size  : 14px;
            font-weight: normal;
            color      : color(primary50);
        }
        .bar {
            position     : absolute;
            border-bottom: 0.5px solid #F0F0F0 !important;
            right        : 0%;
            top          : 56px;
            width        : 100%;
        }

        a{
            text-decoration: none !important;
        }
        .item {
            height       : 40px;
            display      : flex;
            align-items  : center;
            padding      : 0px 4px 0px 4px;
            border-radius: 4px;
            margin       : 0px 8px;
        }

        .items{
            padding: 16px 0px 6px 0px;
        }

        .subItem {
            height      : 40px;
            display     : flex;
            align-items : center;
            padding-left: 20px;
        }

        .subMenuSelected{
            color           : color(neutral100) !important;
            font-weight     : 500;
            opacity         : 1;
            background-color: color(neutral0) !important;
        }

        .item:hover {
            text-decoration: none !important;
            opacity        : 1;
            border-radius  : 4px;
            color          : #3770FB !important;

        }
        .item:focus {
            text-decoration: none !important;
            opacity        : 1;
        }
        .active{
            color           : #3770FB !important;
            opacity         : 1;
            background-color: #ECF4FD;
            border-radius   : 4px;
            background      : rgba(55, 112, 251, 0.1) !important;
            
        }

        .navlinkFont{
            padding: 8px 12px;
        }

        .active path{
              // fill: color(neutral80);
            opacity: 1;
        }
        
        .filters {
            border-top: 0.5px solid color(neutral20);
        }

        .circle1 {
            width           : 35px !important;
            height          : 35px !important;
            border-radius   : 35px;
            background-color: color(white);
        }
        .circle2 {
            width           : 35px !important;
            height          : 35px !important;
            border-radius   : 35px;
            background-color: color(white);
        }
        .img {
            margin: 8px;
        }
        .circle {
            width        : 35px;
            height       : 35px;
            border-radius: 50%;
        }

        .circle1:hover{
            box-shadow: 0px 4px 8px rgba(133, 133, 133, 0.2);

            cursor       : pointer;
            border-radius: 50%;
            transition   : 0.25s ease-in;
        }
        .circle2:hover{
            box-shadow   : 0px 4px 8px rgba(133, 133, 133, 0.2);
            cursor       : pointer;
            border-radius: 50%;
            transition   : 0.25s ease-in;
        }
        .circle:hover{
            box-shadow   : 0px 4px 8px rgba(133, 133, 133, 0.2);
            cursor       : pointer;
            width        : 35px;
            height       : 35px;
            border-radius: 50%;
            transition   : 0.25s ease-in;
        }

        .walletlyHome {
            margin-top: 32px;
        }
        .Heading2 {
            margin-top: 33px;
        }
        .searchIcon {
            float       : right;
            margin-right: 34px;
        }
    }

    .hideSubNav {
        margin-left: -220px;
        transition : all .3s ease-in-out;

        .sidebarHeading{
            height         : 56px;
            display        : flex;
            justify-content: center;
            flex-direction : column;
        }

        .paragraphText {
            font-size  : 14px;
            font-weight: normal;
            color      : color(neutral40)
        }

        .active .paragraphText {
            font-size  : 14px;
            font-weight: normal;
            color      : color(primary50);
        }
        .bar {
            position     : absolute;
            border-bottom: 1px solid #F0F0F0 !important;
            right        : 0%;
            top          : 59px;
            width        : 100%;
        }

        a{
            text-decoration: none !important;
        }
        .item {
            padding-top   : 8px;
            padding-bottom: 8px;
            padding-left  : 16px;
        }

        .items{
            padding: 16px 5px 6px 4px;
        }

        .subItem {
            padding-top   : 8px;
            padding-bottom: 8px;
            padding-left  : 20px;
        }
        .subMenuSelected{
            color           : color(neutral100) !important;
            font-weight     : 500;
            opacity         : 1;
            background-color: color(neutral0) !important;
        }

        .subMenuSelected:hover{
            background-color: color(neutral0) !important;
        }

        .item:hover {
            text-decoration : none !important;
            opacity         : 1;
            background-color: rgba(29, 59, 86, 0.05);
            border-radius   : 4px;
        }
        .item:focus {
            text-decoration : none !important;
            opacity         : 1;
            background-color: rgba(29, 59, 86, 0.05);
        }
        .active{
            color           : #3770FB;
            opacity         : 1;
            background-color: #ECF4FD;
            transition      : ease 0.3s;
            border-radius   : 4px;
            padding         : 8px 12px;
        }

        .active path{
              // fill: color(neutral80);
            opacity: 1;
        }
        
        .filters {
            border-top: 0.5px solid color(neutral20);
        }

        .circle1 {
            width           : 35px !important;
            height          : 35px !important;
            border-radius   : 35px;
            background-color: color(white);
        }
        .circle2 {
            width           : 35px !important;
            height          : 35px !important;
            border-radius   : 35px;
            background-color: color(white);
        }
        .img {
            margin: 8px;
        }
        .circle {
            width        : 35px;
            height       : 35px;
            border-radius: 50%;
        }

        .circle1:hover{
            box-shadow: 0px 4px 8px rgba(133, 133, 133, 0.2);

            cursor       : pointer;
            border-radius: 50%;
            transition   : 0.25s ease-in;
        }
        .circle2:hover{
            box-shadow   : 0px 4px 8px rgba(133, 133, 133, 0.2);
            cursor       : pointer;
            border-radius: 50%;
            transition   : 0.25s ease-in;
        }
        .circle:hover{
            box-shadow   : 0px 4px 8px rgba(133, 133, 133, 0.2);
            cursor       : pointer;
            width        : 35px;
            height       : 35px;
            border-radius: 50%;
            transition   : 0.25s ease-in;
        }

        .walletlyHome {
            margin-top: 32px;
        }
        .Heading2 {
            margin-top: 33px;
        }
        .searchIcon {
            float       : right;
            margin-right: 34px;
        }
    }


    .tooglecircle{
       display: none;
    }
}

@media (max-width: 767px){
    #sidebar {
        .tooglecircle{
            display         : inline;
            z-index         : 1;
            width           : 50px;
            height          : 50px;
            border-radius   : 50px;
            position        : fixed;
            bottom          : 7%;
            right           : 10%;
            background-color: color(neutral90);
        }
        .hidePartial { 
            margin-left: -88px;
            transition : all .6s ease;
        }

        .partailSideNav {
            transition: all .6s ease;

            .brandLogo {
                position: absolute;
                right   : 0%;
                top     : 16px;
                height  : 5.5%;
                left    : 22.3%;
                width   : 54.54%;
            }
            .navPartial {
                position: absolute;
                top     : 83px;
                height  : 62.3%;
                width   : 54.5%;
                  // left     : 22.7%;
            }
        }
        .hideSubNav {
            display: none;
        }

        .overlay {
            height    : 100%;
            width     : 100%;
            position  : fixed;
            background: #000000;
            z-index   : 100;
            opacity   : 0.6;
        }
    }
  }


  @media (min-width: 1500px){ 

    .partailSideNav {
        transition: all .4s ease;

        .brandLogo {
            position: absolute;
            right   : 0%;
            top     : 16px;
            height  : 5.5%;
            left    : 22.3%;
            width   : 54.54%;
        }
        .navPartial {
            position: absolute;
            height  : 62.3%;
            width   : 54.5%;
              // left     : 22.7%;
        }
    }
  }

  @media (min-width: 800px) and (max-width: 1300px){ 

    .partailSideNav {
        transition: all .4s ease;

        .brandLogo {
            position: absolute;
            right   : 0%;
            top     : 16px !important;
            height  : 5.5%;
            left    : 22.3%;
            width   : 54.54%;
        }
        .navPartial {
            position: absolute;
            height  : 62.3%;
            width   : 54.5%;
        }
    }

    .sideBarMain{
        .Heading2{
            margin-top: 8% !important;
        }
    }
  }





