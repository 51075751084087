#Contact{

/* About Page */

#page-header.about-header {
    // background-image: url("img/about/banner.png");
    background-color: #353E54;
    height: 30vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    background-size: cover;
    padding: 14px;

// 
h2,p{
    color: white;
}
}

#about-head {
    display: flex;
    align-items: center;
}

#about-head img {
    width: 50%;
    height: auto;
}

#about-head div {
    padding-left: 40px;
}

#about-app {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    align-items: center;
}

#about-app .video {
    width: 70%;
    height: 100%;
    margin-top: 30px;
}

#about-app .video video {
    width: 100%;
    height: 100%;
    border-radius: 20px;
}


/* Contact Page */

#contact-details {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

#contact-details .details {
    width: 40%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

#contact-details .details span,
#form-details span {
    font-size: 12px;
}

#contact-details .details h2,
#form-details h2 {
    font-size: 26px;
    line-height: 35px;
    padding: 20px 0;
}

#contact-details .details h3 {
    font-size: 16px;
    padding-bottom: 15px;
}

#contact-details .details li {
    list-style: none;
    display: flex;
    padding: 10px 0;
}

#contact-details .details li i {
    font-size: 14px;
    padding-right: 22px;
}

#contact-details .details li p {
    margin: 0;
    font-size: 14px;
}

#contact-details .map {
    width: 55%;
    height: 400px;
}

#contact-details .map iframe {
    width: 100%;
    height: 100%;
}

#form-details {
    display: flex;
    justify-content: space-between;
    margin: 30px;
    padding: 80px;
    border: 1px solid #e1e1e1;
}

#form-details form {
    width: 65%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

#form-details form input,
#form-details form textarea {
    width: 100%;
    padding: 12px 15px;
    outline: none;
    margin-bottom: 20px;
    border: 1px solid #e1e1e1;
}

#form-details form button {
    background-color: #aaaaaa;
    color: #fff;
}

#form-details .people div {
    display: flex;
    align-items: flex-start;
    padding-bottom: 25px;
}

#form-details .people img {
    width: 65px;
    height: 65px;
    object-fit: cover;
    margin-right: 15px;
}

#form-details .people p {
    margin: 0;
    font-size: 13px;
    line-height: 25px;
}

#form-details .people p span {
    display: block;
    font-size: 16px;
    font-weight: 600;
    color: #000;
}
}