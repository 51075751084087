/* ---------------------------------------------------
    HELPERS SCSS
----------------------------------------------------- */
@import "./helpers/color-scheme.scss";
@import "./helpers/font-size.scss";
@import "./helpers/spacing.scss";
@import "./helpers/width.scss";
@import "./helpers/height.scss";
@import "./helpers/opacity.scss";
@import "./helpers/border.scss";
@import "./helpers/z-index.scss";
@import "./helpers/flex-layout.scss";
@import "./helpers/text-style.scss";
@import "./helpers/padding.scss";
@import "./helpers/absolute-spacing.scss";
@import "./helpers/margin.scss";
@import "./helpers/line-height.scss";
/* ---------------------------------------------------
    PAGES SCSS
----------------------------------------------------- */
@import "./pages/auth.scss";
@import "./pages/contact.scss";
@import "./pages/notFound.scss";
@import "./pages/wainoexplorer.scss";
@import "./pages/merchant.scss";
@import "./pages/orders.scss";
@import "./pages/checkoutsuccess.scss";

//Admin SCSS
@import "./pages/admin/dashboard.scss";

/* ---------------------------------------------------
SubPages
----------------------------------------------------- */

// @import "./pages/redemption/subpages/redemption-create.scss";
// @import "./pages/referral/subpages/analytics.scss";
// @import "./pages/referral/subpages/participations.scss";

/* ---------------------------------------------------
    Components SCSS
----------------------------------------------------- */

@import "../css/component/emailverify.scss";

// @import "./component/sidebar.scss";
// @import "./component/header.scss";
// @import "./component/email-verify.scss";
// @import "./component/cards.scss";
// @import "./component/signup-header.scss";
// @import "./component/referral-header.scss";
// @import "./component/tables.scss";
// @import "./component/sidebar-with-buttons.scss";
// @import "./component/custom-slider.scss";
// @import "./component/setting-header.scss";
// @import "./component//brands-dropdown.scss";
// @import "./component/toaster.scss";
// @import "./component/customtiercard.scss";
// @import "./component/custompass.scss";
// @import "./component/imagedropbox.scss";
// @import "./component/designdropdown.scss";
// @import "./component/viewdetail.scss";
// @import "./component/tagtextarea.scss";
// @import "./component/fit-content-input.scss";
// @import "./component/active-status.scss";
// @import "./component/copy-text.scss";
// @import "./component/draggable.scss";
// @import "./component/customimagedrop.scss";
// @import "./component/customtexteditor.scss";
// @import "./component/customselect.scss";
// @import "./component/button.scss";
// @import "./component/radio.scss";
// @import "./component/customtablev2.scss";
// @import "./component/customscrollcomponent.scss";

@import "./component/navbar.scss";
@import "./component/hero.scss";
@import "./component/footer.scss";
@import "./component/cart.scss";

//Global
p {
  margin: 0px;
}

body {
  -webkit-font-smoothing: antialiased;
  margin: 0px;
}

.d-flex{
  display: flex;
}

.trigger1 {
  padding-top: 11px;
  border: 2px;
  text-align: center;
  border-color: rgba(0, 132, 255, 0.21);
  border-style: dashed;
  font-size: 14px;
  border-radius: 6px;
  color: #0084ff;
  cursor: pointer;
}

.trigger1:hover {
  border-color: #0084ff;
  background-color: #0084ff0f !important;
  transition:
    background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.trigger2 {
  padding-top: 11px;
  border: 2px;
  text-align: center;
  border-color: #afead0;
  border-style: dashed;
  font-size: 14px;
  border-radius: 6px;
  color: #5cd59d;
  cursor: pointer;
}

.line-through {
  text-decoration: line-through;
  text-decoration-color: black;
  color: lightgrey;
}

.ribbon {
  position: absolute;
  left: 0px;
  border-radius: 4px;
  top: 0px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
.ribbon > .content {
  color: white;
  font-size: 1.25rem;
  text-align: center;
  font-weight: 400;
  background: orange;
  border-left: 0px !important;
  transition:
    clip-path 1s,
    padding 1s,
    background 1s;
}

.newWineBanner {
  margin: 4px;
}

.trigger2:hover {
  border-color: #5cd59d;
  background-color: #e0f7e3 !important;
  transition:
    background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.or {
  position: absolute;
  right: 385px;
  top: 80px;
}

.overflow-scroll {
  overflow: scroll;
}

//Empty State
.emptyState {
  display: flex;
  flex-direction: column;
  gap: 32px;
  margin-top: 12%;
  align-items: center;
}
.emptyState-sm {
  padding: 40px 0px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-items: center;
}
.emptyImage {
  height: 80px;
  width: 80px;
}

.emptyImage-sm {
  height: 48px;
  width: 48px;
}

.section-p1 {
  padding: 40px 80px;
}

.emptyBody {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-direction: column;
}

#newImage {
  height: 20px !important;
  width: 20px !important;
}

.place-self-end {
  place-self: end;
}

.display {
  display: unset !important;
}

.place-self-center {
  place-self: center;
}

.d-none {
  display: none !important;
}

.text-end {
  text-align: end;
}

.middle {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.line-height-10 {
  line-height: 10px !important;
}

.headerCollapseIcon:hover {
  svg rect {
    fill: color(primary50);
  }
}

.float-left {
  float: left;
}

.align-self-center {
  align-self: center;
}

.absoluteMiddle {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.secondaryButton {
  background-color: color(white) !important;
  color: color(neutral100) !important;
  border: 1px solid #dfe1e6 !important;
}

.w-fit {
  width: fit-content !important;
}

.pr {
  position: relative;
}

.pa {
  position: absolute;
}

.cp {
  cursor: pointer;
}

.cd {
  cursor: default;
}

.cna {
  cursor: not-allowed;
}

.cn {
  cursor: not-allowed;
}

.bg-transparent {
  background: transparent;
}

.underline {
  text-decoration: underline;
}

.color-inherit {
  color: inherit !important;
}

.justify-content-end {
  justify-content: end;
}
.justify-content-center {
  justify-content: center;
}

.disabledbutton {
  pointer-events: none !important;
  opacity: 0.5 !important;
}

.align-items-flex-start {
  align-items: flex-start;
}

.rotate {
  transition: all ease-in-out 0.3s;
}

.rotate-top {
  transform: rotate(180deg);
  transition: all ease-in-out 0.3s;
}

.rotate-right {
  transform: rotate(270deg);
}

.flex-grow {
  flex: 1 1 0px;
}

.rotate-left {
  transform: rotate(90deg);
}

.rotate-bottom {
  transform: rotate(0deg);
}

.rotate-45 {
  transform: rotate(45deg);
}

.flip {
  transform: scale(-1, 1);
}

.disabled {
  cursor: default;
  pointer-events: none !important;
  opacity: 0.3;
}

.enabled {
  cursor: pointer;
  pointer-events: all;
  opacity: 1;
}

.position-unset {
  position: unset !important;
}

.text-dec-none {
  text-decoration: none !important;
}

.pe-none {
  pointer-events: none;
}

.bg-color-inherit {
  background-color: inherit !important;
}

a.hover-none:hover {
  color: inherit;
}

.overlay {
  display: none;
  position: fixed;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.7);
  z-index: 998;
  opacity: 0;
  top: 0;
  transition: all 0.5s ease-in-out;
}

.overflow-hidden {
  overflow: hidden;
}

.xl {
  padding: 16px 24px !important;
}

.l {
  padding: 12px 24px !important;
}

.m {
  padding: 8px 24px !important;
}

.s {
  padding: 6px 24px !important;
}

.tableRowHover:hover {
  background-color: color(neutral0);
}

//LogoUploader
.imageCover {
  height: 80px;
  width: 163px;
  object-fit: cover;
}

.display-none {
  display: none !important;
}

.content {
  width: 100%;
  // overflow-y    : scroll;
  // height: 100vh;
  z-index: 0;
  padding-top: 0px;
  padding-left: 0px;
  padding-right: 0px;
  overflow-x: hidden;
  background: color(white);
  border-left: 0.5px solid color(neutral0);
  // border-radius : 24px 0px 0px 24px;
}

.align-self {
  align-self: center;
}

.dropdown-box {
  border: 1px solid color(neutral0);
  border-radius: 8px;
  position: absolute;
  background-color: white;
  z-index: 1000;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.25);
  transition: opacity ease 0.7s;
  max-height: 250px;
  overflow-y: scroll;
  z-index: 11 !important;
}
.transition {
  transition: all 0.2s ease-in-out;
}
.grayed-image {
  -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
  filter: grayscale(100%);
}

.togglebtn {
  cursor: pointer;
}

.lineheight-0 {
  line-height: 0;
}

label {
  font-size: 16px;
}

.text-align-right {
  text-align: right;
  text-align: -webkit-right;
}

.object-fit-cover {
  object-fit: cover;
}

.object-fit-contain {
  object-fit: contain;
}

.text-align-left {
  text-align: left;
}

.justify-content-right {
  justify-content: right;
}

.justify-content-space-between {
  justify-content: space-between;
}

.text-align-center {
  text-align: center;
}

.position-fixed {
  position: fixed;
}

.d-grid {
  display: grid;
}

.grid-auto {
  grid-template-columns: auto auto auto;
}

.word-break {
  word-break: break-word;
}

.overflow-wrap-anywhere {
  overflow: hidden;
}

.justify-space-evenly {
  justify-content: space-evenly;
}

.white-space-pre {
  white-space: pre;
}

.align-items-right {
  align-items: flex-end;
}

.align-items-left {
  align-items: flex-start;
}

.l-0 {
  left: 0;
}

.b-0 {
  bottom: 0;
}

.line-through {
  text-decoration: line-through;
}

.bar {
  // border-bottom: 0.5px solid color(neutral10) !important;
  border-bottom: 0.5px solid color(neutral20) !important;
  width: 100%;
  z-index: 0;
  left: 0px;
}

.saveAndCloseBtn:focus {
  outline: none;
}

.outline-none:focus-visible {
  outline: 0px !important;
  border: 0px !important;
}

.wrapRemove {
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  .background-inherit {
    background-color: inherit;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }
}

.rangePoints::after {
  content: "Points";
  margin-left: 4px;
}

.UploadImage {
  border: 1px dashed color(neutral60);
  box-sizing: border-box;
  border-radius: 8px;
  padding: 8px 16px;
  width: 144px;
  color: color(neutral60);
  font-size: 12px;
}

.space-between {
  justify-content: space-between;
}

.object-fit-contain {
  object-fit: contain !important;
}

.space-around {
  justify-content: space-around;
}

.border-none {
  border: 0px !important;
}

.justify-flex-end {
  justify-content: flex-end;
}

.justify-flex-start {
  justify-content: flex-start;
}

.max-h-100 {
  max-height: 100% !important;
}

.accordinIcon {
  margin-top: -2px !important;
}

.accordionBorder {
  border: 1px solid color(neutral20);
}

.capitalize {
  text-transform: capitalize;
}

.upperCase {
  text-transform: uppercase;
}

.position-relative {
  position: relative;
}

.resize-none {
  resize: none;
}

.customLabel {
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 24px;
  color: color(neutral80);
}

.vertical-align-super {
  vertical-align: super;
}

.component {
  padding: 32px;
}

.vertical-align-bottom {
  vertical-align: text-bottom;
}

.customTagHeight {
  min-height: 200px;
}

.align-items-center {
  align-items: center;
}

//used to fix the shadow of sidebar, this class is added in sidebarNav class
.handleSiderBarShadow {
  z-index: 3 !important;
}

.cursor-nodrop {
  cursor: no-drop !important;
}

//customtablecomponentstyle
.tableDesign {
  border-radius: 16px;
  background-color: color(neutral0);
  padding: 16px;
}

//tab below Bar

.customTabBar {
  // border-bottom: 2px solid #F0F0F0 !important;
  border-bottom: 1px solid rgba(224, 224, 224, 1);
  width: 100%;
  z-index: 0;
  left: 0px;
}

.white-space-pre {
  white-space: pre;
}

.white-space-no-wrap {
  white-space: nowrap;
}

.uppercase {
  text-transform: uppercase;
}

.defaultLandingPageImage {
  height: 100vh;
  width: inherit;
}

.backgroundCover {
  background-size: cover !important;
}

.verticalEllipses {
  display: -webkit-box;
  max-width: 100%;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.SimpleSelect {
  position: relative;
  border-radius: 6px;
  color: color(neutral80);
  background-color: color(white);
  border: 1px solid color(neutral20);
  font-size: 14px;
  padding: 8px 8px 10px 8px;
  cursor: pointer;
  text-transform: capitalize;
  height: 36px;
  -webkit-appearance: none !important;
  background: url("../img/dropdownIcon.png") no-repeat 95% 13px;
}

.SimpleSelect:focus,
.SimpleSelect:hover {
  border-radius: 8;
  background-color: color(white);
  border: 1px solid color(primary50);
}

.loaderOuterPage {
  height: 100%;
}

#CampaignFunctions {
  .borderSet {
    border: 1px solid color(neutral10);
  }
}

#dateFilter {
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.08);
  border-radius: 4px;
}

.blurBg {
  filter: blur(62px);
  -webkit-filter: blur(62px);
}

.hoverCollapse:hover {
  fill: #2f80ed;
}

.edit-border {
  border-bottom: 2px solid #e1e5ea;
  box-sizing: border-box;
}

.box-shadow {
  box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.12);
}

.ellipses {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.fitContentInputField {
  border: none;
  padding: 2px 0px;
  background-color: transparent;
  color: #000000;
  cursor: pointer;
  width: 100%;
}

.fitContentInputField::placeholder {
  color: #000000;
  padding: 2px 0px;
}

.fitContentInputField:focus + .titleBar {
  display: none;
  transition: all 0.2s ease-in-out;
}

.titleBar {
  background-color: color(neutral20);
  border-radius: 3px;
  height: 2px;
}

.resize-none {
  resize: none;
}

.fitContentInputField:focus {
  outline: 0px;
  text-decoration: none;
}

.mainLoader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.error {
  color: color(danger30) !important;
  text-align: left;
  margin-top: 8px;
  font-size: 10px;
  line-height: 16px;
}

.centerAlign {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

/* ---------------------------------------------------
    Media Queries
----------------------------------------------------- */

/*  For Sidebar */
@media (max-width: 767px) {
}

.p_0 {
  padding: 0px !important;
}

.imageSection {
  position: fixed;
  right: 0px;
  top: 0px;

  .sideImage {
    width: 100%;
    height: 100vh;
  }
}

/* xs */
@media (max-width: 575px) {
  body {
    font-size: 4vw;
  }
  .main-heading {
    font-size: 7vw;
  }
}
/* xs */

@media (min-width: 360px) and (max-width: 575px) {
}

@media (min-width: 576px) and (max-width: 767px) {
}
/* md */
@media (min-width: 768px) and (max-width: 991px) {
}
/* lg */
@media (min-width: 992px) and (max-width: 1199px) {
}
/* xl */
@media (min-width: 1200px) {
}
/* xxl */
@media (min-width: 1600px) {
  // #Main {
  //   height: 100vh;
  // }
  .widthFixLandingPage {
    width: 100% !important;
  }
}

#imageModalV2 {
  button:focus {
    outline: none !important;
  }
}

// .reactEasyCrop_CropArea{
// color  : white !important;
// opacity: 0.5;
// box-shadow : unset !important;
// border     : none !important;
// }
// .reactEasyCrop_Container{height: 470px;}

// // crop
// .reactEasyCrop_CropArea{
//   // width     : 100% !important;
//   // height    : 100% !important;
//   left      : 0% !important;
//   top       : 0% !important;
//   transform : none !important;
// }
// .reactEasyCrop_Contain{
//   top    : -88px !important;
//   margin : 0px !important;
// }

//customAutoCompleteCheckBox in audience filter

// #CustomSelect{
.MuiListItem-root {
  font-size: 14px !important;
  font-weight: 500 !important;
  line-height: 16px !important;
  font-family: "Inter";
  background-color: color(white) !important;
  color: color(neutral100) !important;
}
.MuiListItem-root.Mui-selected,
.MuiListItem-root.Mui-selected:hover {
  background-color: color(white) !important;
  color: color(primary50) !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  line-height: 16px !important;
  font-family: "Inter";
}
.MuiListItem-button:hover {
  background-color: color(white) !important;
  color: color(primary50) !important;
}

.text-ellipsis--2 {
  text-overflow: ellipsis;
  overflow: hidden;
  // Addition lines for 2 line or multiline ellipsis
  display: -webkit-box !important;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  white-space: normal;
}

.phoneField {
  color: color(neutral100) !important;
  border: 1px solid color(neutral20) !important;
  width: 100% !important;
  box-sizing: border-box !important;
  outline: 0px !important;
  letter-spacing: 0.1px !important;
  transition: all 0.2s ease-in !important;
  height: 42px !important;
  background-color: #f2f6ff !important;
}

.phoneField:focus {
  background: #ffffff !important;
  box-shadow: 0 0 0 0.1rem color(primary20);
}

#grouped-demo:hover {
  fieldset {
  }

  .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
    border-color: color(primary50) !important;
  }
}

#TextfieldUpdated,
.phoneField,
#grouped-demo,
#SelectUpdated {
  textarea {
    resize: none;
  }

  input,
  .phoneField,
  textarea,
  select,
  .fitContentInputField {
    color: color(neutral100);
    border: 1px solid color(neutral20) !important;
    width: 100%;
    padding: 10px 8px;
    box-sizing: border-box;
    outline: 0px;
    background-color: #f2f6ff !important;
    letter-spacing: 0.1px;
    transition: all 0.2s ease-in;
  }

  input::placeholder,
  textarea::placeholder {
    color: color(neutral40);
  }

  select {
    height: 40px;
    padding: 8px 8px !important;
  }

  input[type="number"] {
    line-height: 30px;
    height: 40px;
  }

  input[type="number"]::-webkit-inner-spin-button {
    height: 24px;
  }

  input:hover,
  textarea:hover,
  select:hover {
    border-color: color(primary50) !important;
  }

  input:focus,
  textarea:focus,
  select:focus {
    background: #ffffff !important;
    border: 1px solid color(primary50) !important;
  }

  .FocusedIcon {
    color: color(primary50) !important;

    svg,
    svg path {
      color: color(primary50) !important;
      fill: color(primary50) !important;
    }
  }
}

.MuiIconButton-colorSecondary:hover {
  background-color: color(neutral20) !important;
}

#Toaster .notification-container {
  font-size: 14px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  position: fixed;
  z-index: 1;
}

#Toaster .top-right {
  top: 40px;
  right: 12px;
  -webkit-transition: -webkit-transform 0.6s ease-in-out;
  transition: -webkit-transform 0.6s ease-in-out;
  transition: transform 0.6s ease-in-out;
  transition:
    transform 0.6s ease-in-out,
    -webkit-transform 0.6s ease-in-out;
  -webkit-animation: toast-in-right 0.7s;
  animation: toast-in-right 0.7s;
}

#Toaster .bottom-right {
  bottom: 12px;
  right: 12px;
  -webkit-transition: -webkit-transform 0.6s ease-in-out;
  transition: -webkit-transform 0.6s ease-in-out;
  transition: transform 0.6s ease-in-out;
  transition:
    transform 0.6s ease-in-out,
    -webkit-transform 0.6s ease-in-out;
  -webkit-animation: toast-in-right 0.7s;
  animation: toast-in-right 0.7s;
}

#Toaster .top-left {
  top: 12px;
  left: 12px;
  -webkit-transition: -webkit-transform 0.6s ease-in;
  transition: -webkit-transform 0.6s ease-in;
  transition: transform 0.6s ease-in;
  transition:
    transform 0.6s ease-in,
    -webkit-transform 0.6s ease-in;
  -webkit-animation: toast-in-left 0.7s;
  animation: toast-in-left 0.7s;
}

#Toaster .bottom-left {
  bottom: 12px;
  left: 12px;
  -webkit-transition: -webkit-transform 0.6s ease-in;
  transition: -webkit-transform 0.6s ease-in;
  transition: transform 0.6s ease-in;
  transition:
    transform 0.6s ease-in,
    -webkit-transform 0.6s ease-in;
  -webkit-animation: toast-in-left 0.7s;
  animation: toast-in-left 0.7s;
}

.checked {
  color: orange;
}

#Toaster .notification {
  background-color: #f5f7fc;
  -webkit-transition: 0.3s ease;
  transition: 0.3s ease;
  position: relative;
  pointer-events: auto;
  overflow: hidden;
  margin: 0 0 6px;
  padding: 16px;
  margin-bottom: 15px;
  width: 300px;
  max-height: 100px;
  border-radius: 8px;
  -webkit-box-shadow: 0px 0px 12px rgba(128, 128, 128, 0.24);
  box-shadow: 0px 0px 12px rgba(128, 128, 128, 0.24);
  color: #000;
  opacity: 0.9;
  background-position: 15px;
  background-repeat: no-repeat;
}

#Toaster .notification:hover {
  -webkit-box-shadow: 0 0 12px #fff;
  box-shadow: 0 0 12px #fff;
  opacity: 1;
  cursor: pointer;
}

#Toaster .notification-title {
  font-weight: 700;
  font-size: 16px;
  text-align: left;
  margin-top: 0;
  margin-bottom: 6px;
  width: 300px;
  height: 18px;
  color: inherit;
}

#Toaster .notification-message {
  margin: 0;
  text-align: left;
  margin-left: -1px;
  overflow: hidden;
  text-overflow: ellipsis;
  color: inherit;
}

#Toaster .notification-image {
  float: left;
  margin-right: 15px;
  margin-top: 5px;
}

#Toaster .notification-image img {
  width: 30px;
  height: 30px;
}

#Toaster .toast {
  min-height: 50px;
  width: 324px;
  color: #fff;
  padding: 16px;
}

#Toaster .notification-container button {
  font-weight: 700;
  color: inherit;
  outline: none;
  border: none;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.8;
  line-height: 1;
  font-size: 16px;
  padding: 0;
  cursor: pointer;
  background: 0 0;
  border: 0;
}

#Toaster .error {
  color: #f25053;
}

#Toaster .warning {
  color: #f2a750;
}

#Toaster .info {
  color: #3456e8;
}

#Toaster .success {
  color: #30be64;
}

#Toaster .notification-message {
  color: #45526b;
}

#CustomRadio {
  .MuiRadio-colorSecondary.Mui-checked {
    color: color(primary50) !important;
    &:hover {
      background-color: color(neutral20) !important;
    }
  }
  .MuiIconButton-colorSecondary:hover {
    background-color: color(neutral20) !important;
  }
}

#CustomCheckBox {
  // .MuiIconButton-colorSecondary:hover{background-color: color(neutral20) !important;}
  .MuiCheckbox-colorSecondary.Mui-checked {
    color: color(primary50) !important;
    // &:hover{
    //     background-color: color(neutral20) !important;
    // }
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

#grouped-demo {
  z-index: 10000000;
}

html {
  overflow: scroll;
  overflow-x: hidden;
}
::-webkit-scrollbar {
  width: 0; /* Remove scrollbar space */
  background: transparent; /* Optional: just make scrollbar invisible */
}
/* Optional: show position indicator in red */
::-webkit-scrollbar-thumb {
  background: #ff0000;
}
