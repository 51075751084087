#Footer{
    footer {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }
    
    footer .logo {
        margin-bottom: 30px;
    }
    
    footer .follow {
        margin-top: 20px;
    }
    
    footer .follow i {
        color: #465b52;
        padding-right: 4px;
        cursor: pointer;
    }
    
    footer h4 {
        font-size: 14px;
        padding-bottom: 20px;
    }
    
    footer p {
        font-size: 13px;
        margin: 0 0 8px 0;
    }
    
    footer .col {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 20px;
    }
    
    footer a {
        font-size: 13px;
        text-decoration: none;
        margin-bottom: 10px;
        color: #222;
    }
    
    footer .install .row img {
        border: 1px solid #aaaaaa;
        border-radius: 6px;
    }
    
    footer .install img {
        margin: 10px 0 15px 0;
    }
    
    footer .copyright {
        width: 100%;
        text-align: center;
    }
    
    footer .follow i:hover,
    footer a:hover {
        color: #aaaaaa;
    }
    
    
    .section-p1{
        padding: 40px 80px;
    }


        
}