#Hero{
    height: 100%;
        #hero {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
            padding: 0 80px;
            width: 100%;
            background-size: cover;
            height: calc(100vh - 80px);
            background-position: top 25% right 0;
        }

        #hero h2, #hero h4{
            color: #fff;
            text-shadow: black 0.1em 0.1em 0.2em;
        }


        #hero h1 {
            color: #aaaaaa;
            // text-shadow: black 0.1em 0.1em 0.2em

        }

        #hero p{
            color: #aaaaaa;
            text-shadow: black 0.1em 0.1em 0.2em
        }

        #hero button {
            margin-top: 10px;
            background-color: transparent;
            color: #fff;
            border: 0;
            padding: 14px 80px 14px 65px;
            background-repeat: no-repeat;
            cursor: pointer;
            font-weight: 700;
            font-size: 15px;
        }

        #product1 {
            text-align: center;
        }

        .section-p1{
            padding: 40px 80px;
        }
        
        #product1 .pro-container {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            padding-top: 20px;
        }
        
        #product1 .pro {
            width: 23%;
            min-width: 250px;
            padding: 10px 12px;
            border: 1px solid #cce7d0;
            border-radius: 25px;
            transition: .2s;
            position: relative;
            cursor: pointer;
            box-shadow: 20px 20px 30px rgba(0, 0, 0, 0.02);
            margin: 15px 0;
        }
        
        #product1 .pro:hover {
            box-shadow: 20px 20px 30px rgba(0, 0, 0, 0.06);
        }
        
        #product1 .pro img {
            width: 100%;
            border-radius: 20px;
        }
        
        #product1 .pro .des {
            text-align: start;
            padding: 10px 0;
        }
        
        #product1 .pro .des span {
            color: #606063;
            font-size: 12px;
        }
        
        #product1 .pro .des h5 {
            padding-top: 7px;
            color: #1a1a1a;
            font-size: 14px;
            transition: 0.2s;
        }
        
        #product1 .pro:hover .des h5 {
            color: #aaaaaa;
        }
        
        #product1 .pro .des i {
            color: rgb(240, 177, 18);
            font-size: 12px;
        }
        
        #product1 .pro .des h4 {
            padding-top: 7px;
            font-size: 15px;
            font-weight: 700;
            color: #aaaaaa;
        }
        
        #product1 .pro .cart {
            width: 40px;
            height: 40px;
            line-height: 40px;
            border-radius: 50px;
            background-color: #e8f6ea;
            font-weight: 500;
            color: #aaaaaa;
            border: 1px solid #cce7d0;
            transition: 0.2s;
            position: absolute;
            bottom: 20px;
            right: 10px;
        }
        
        #product1 .pro .cart:hover {
            color: #fff;
            background: #aaaaaa;
            font-weight: 400;
        }

        #feature {
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-wrap: wrap;
        }
        
        #feature .fe-box {
            width: 180px;
            text-align: center;
            padding: 25px 15px;
            border-radius: 4px;
            border: 1px solid #cce7d0;
            box-shadow: 20px 20px 34px rgba(0, 0, 0, .03);
            transition: 0.3s ease;
            margin: 15px 0;
        }
        
        #feature .fe-box:hover {
            box-shadow: 10px 10px 54px rgba(70, 62, 221, 0.1);
        }
        
        #feature .fe-box img {
            width: 100%;
            margin-bottom: 10px;
        }
        
        #feature .fe-box h6 {
            display: inline-block;
            padding: 9px 8px 6px 8px;
            line-height: 1;
            border-radius: 4px;
            color: #aaaaaa;
            background-color: #fddde4;
        }
        
        #feature .fe-box:nth-child(2) h6 {
            background-color: #cdebbc;
        }
        
        #feature .fe-box:nth-child(3) h6 {
            background-color: #d1e8f2;
        }
        
        #feature .fe-box:nth-child(4) h6 {
            background-color: #cdd4f8;
        }
        
        #feature .fe-box:nth-child(5) h6 {
            background-color: #f6dbf6;
        }
        
        #feature .fe-box:nth-child(6) h6 {
            background-color: #fff2e5;
        }

        #banner {
            // background-image: url("img/banner/b2.jpg");
            width: 100%;
            height: 40vh;
            background-size: cover;
            background-position: center;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            text-align: center;
        }
        
        #banner h4 {
            color: #fff;
            font-size: 16px;
        }
        
        #banner h2 {
            color: #fff;
            font-size: 30px;
            padding: 10px 0;
        }
        
        #banner h2 span {
            color: #ef3636;
        }
        
        #banner button:hover {
            background: #aaaaaa;
            color: #fff;
        }
        
        #sm-banner {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
        }
        
        #sm-banner .banner-box {
            // background-image: url("img/banner/b17.jpg");
            min-width: 580px;
            height: 50vh;
            background-size: cover;
            background-position: center;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            padding: 30px;
        }
        
        #sm-banner .banner-box2 {
            // background-image: url("img/banner/b10.jpg");
        }
        
        #sm-banner h4 {
            color: #fff;
            font-size: 20px;
            font-weight: 300;
        }
        
        #sm-banner h2 {
            color: #fff;
            font-size: 28px;
            font-weight: 800;
        }
        
        #sm-banner span {
            color: #fff;
            font-size: 14px;
            font-weight: 500;
            padding-bottom: 15px;
        }
        
        #sm-banner .banner-box:hover button,
        #sm-banner .banner-box2:hover button {
            background: #aaaaaa;
            border: 1px solid #aaaaaa;
            transition: 0.2s ease;
        }
        
        #banner3 {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            padding: 0 80px;
        }
        
        #banner3 .banner-box {
            // background-image: url("img/banner/b7.jpg");
            min-width: 30%;
            height: 30vh;
            background-size: cover;
            background-position: center;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            padding: 20px;
            margin-bottom: 20px;
        }
        
        #banner3 .banner-box2 {
            // background-image: url("img/banner/b4.jpg");
        }
        
        #banner3 .banner-box3 {
            // background-image: url("img/banner/b18.jpg");
        }
        
        #banner3 h2 {
            color: #fff;
            font-weight: 900;
            font-size: 22px;
        }
        
        #banner3 h3 {
            font-size: 15px;
            font-weight: 800;
            color: #ec544e;
        }

        #newsletter {
            // background-image: url("img/banner/b14.png");
            background-repeat: no-repeat;
            background-position: 20% 30%;
            background-color: #041e42;
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-wrap: wrap;
        }

        #newsletter h4 {
            font-size: 22px;
            font-weight: 700;
            color: #fff;
        }

        #newsletter p {
            font-size: 14px;
            font-weight: 600;
            color: #818ea0;
        }

        #newsletter p span {
            color: #ffbd27;
        }

        #newsletter input {
            height: 3.125rem;
            padding: 0 1.25rem;
            font-size: 14px;
            width: 100%;
            border: 1px solid transparent;
            border-radius: 4px;
            border-top-right-radius: 0px;
            border-bottom-right-radius: 0px;
            outline: none;
        }

        #newsletter .form {
            display: flex;
            width: 40%;
        }

        #newsletter button {
            background-color: #aaaaaa;
            color: #fff;
            white-space: nowrap;
            border-top-left-radius: 0px;
            border-bottom-left-radius: 0px;
        }


}

  @media screen and (max-width: 600px) {

    #Hero{
        #hero{
            padding: 0px 20px !important;
            height: calc(100vh);
            background-position: bottom !important;
        }
    }
}